<template>
  <div>
    <div class="row my-3">
      <b-col md="5" class="pr-0">

        <div class="col-12 pl-0">
          <b-input-group>
            <b-input-group-prepend>
              <b-button variant="success" @click="userId = 0"> <b-icon icon="plus"></b-icon> </b-button>
            </b-input-group-prepend>
            <b-form-input v-if="usersType === 'driver'" placeholder="Indicativ ..." v-model="filters.identifier" @keyup.enter="getUsr"></b-form-input>
            <b-form-input v-if="usersType === 'driver'" placeholder="Caută utilizator ..." v-model="filters.search" @keyup.enter="getUsr"></b-form-input>
            <b-form-input v-else placeholder="Caută utilizator ..." v-model="filters.search" @keyup.enter="getUsr"></b-form-input>
            <b-select v-if="usersType === 'driver'" v-model="filters.isBanned" @change="getUsr">
              <b-select-option :value="null">Toate</b-select-option>
              <b-select-option :value="1">Blocate</b-select-option>
              <b-select-option :value="0">Active</b-select-option>
            </b-select>
            <b-input-group-append>
              <b-button @click="getUsr" variant="info">
                <b-icon icon="search"></b-icon>
              </b-button>
              <b-button @click="resetFilters" variant="danger">
                <b-icon icon="x"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>

        <div class="users-list">
          <b-card no-body class="users-list-item  px-2 py-2" @click="userId = user.id" v-for="user in users" :role="usersType" :key="user.id">
            <b-row align-v="center">
              <b-col md="2" v-if="usersType === 'driver'">
                 <b-icon :style="user.is_online === true ? '' : 'opacity:0.2'" icon="circle-fill" :variant="user.is_online === true ? 'success' : 'dark'" scale="0.8" class="mr-1"></b-icon>
                <strong  v-if="user.default_car">{{ user.default_car.indicativ }}</strong>
                <strong  v-else>xxx</strong>
              </b-col>
              <b-col md="2" v-else>
                <strong>{{ user.id }}</strong>
              </b-col>
              <b-col :md="usersType === 'driver' ? 7 : 10">
                <span>{{ user.name }}</span>
                <b-badge variant="light" class="pull-right"> {{ usersType === 'driver' ? 'id: ' + user.id : user.phone  }}</b-badge>
              </b-col>
              <b-col  v-if="usersType === 'driver'">
                  <b-button class="pull-right ml-2" size="sm" @click="remove(user.id)" variant="danger"> <b-icon icon="x"></b-icon> </b-button>
                  <UserBanControl class="pull-right" v-if="user.roles.includes('driver')" :user="user"></UserBanControl>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
      <b-col md="7" class="pl-0">
        <div class="users-detail" v-if="userId > 0">
          <UserDetail :user-id="userId" />
        </div>
        <div class="users-detail" v-else>
          <h6>Adaugă utilizator nou</h6>
          <AddUser :user-id="0" />
        </div>
      </b-col>
    </div>
    <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
    >
    </b-pagination>
  </div>
</template>

<script>
import config from '@/config'
import UserBanControl from '@/components/users/UserBanControl'
import UserDetail from '@/components/users/UserDetail'
import AddUser from '@/components/users/AddUser'

export default {
  name: 'UsersList',
  components: {
    UserBanControl,
    UserDetail,
    AddUser
  },
  props: {
    usersType: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.getUsrId()
    this.getUsr()
  },
  data () {
    return {
      userId: '',
      meta: null,
      users: [],
      role: '',
      perPage: 30,
      currentPage: 1,
      filters: {
        search: '',
        identifier: '',
        isBanned: null
      }
    }
  },
  watch: {
    currentPage: function () {
      this.getUsr()
    },
    usersType: function (value) {
      if (value !== 'driver') {
        this.filters.isBanned = null
      }
      this.getUsr()
    }
  },
  computed: {
    rows () {
      return this.meta ? this.meta.total : 0
    },
    apiUrl () {
      return config.baseApiUrl + '/api/users?role=' + this.usersType
    },
    apiFilters () {
      const filters = Object.keys(this.filters)
        .filter((k) => this.filters[k] !== null && this.filters[k] !== '')
        .reduce((a, k) => ({ ...a, [k]: this.filters[k] }), {})
      filters.role = this.usersType
      filters.page = this.currentPage
      return filters
    }
  },
  methods: {
    getUsrId (getId) {
      this.userId = getId
    },
    getUsr () {
      this.$axios.get(config.baseApiUrl + '/api/users', {
        params: this.apiFilters
      })
        .then((response) => {
          this.users = response.data.data
          this.meta = response.data.meta
        })
      console.log(this.users)
    },
    fetchApiData (apiUrl, httpOptions) {
      return this.$axios.get(apiUrl, httpOptions)
    },
    transformApiResponse (response) {
      return response
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    remove (id) {
      if (confirm('Remove?')) {
        this.$axios.delete(config.baseApiUrl + '/api/users/' + id)
          .then(() => {
            this.getUsr()
            this.userId = ''
          })
      }
    },
    resetFilters () {
      for (const prop in this.filters) {
        this.filters[prop] = ''
      }
      this.$nextTick(() => {
        this.getUsr()
      })
    }
  }
}
</script>

<style scoped>
  .table tr th,
  .table tr td {
    vertical-align: middle;
  }
</style>
