<template>
  <div>

    <h4 v-if="user.id !== 0" class="d-flex align-items-center">
      <div v-if="user.id && user.roles.includes('driver')">
        <b-badge class="mr-2" variant="dark">#{{ user.id }}</b-badge>
        <b-badge class="mr-2" variant="primary">{{ user.default_car ? user.default_car.indicativ : 'XXX' }}</b-badge>
      </div>
      <div v-else>
        <b-badge class="mr-2" variant="dark">#{{ user.id }}</b-badge>
      </div>
      <div>
        <b-badge variant="success" v-if="user.id && user.roles.includes('client') && user.fcm_token !== null">APP</b-badge> {{ user.name }}
      </div>
      <div>
        <b-form-rating class="mx-3" v-model="rating" readonly></b-form-rating>
      </div>
      <div class="ml-auto"  v-if="user.id && user.roles.includes('driver')">
        <b-badge v-if="user.is_online === true" variant="success">Online</b-badge>
        <b-badge v-else variant="secondary">Offline</b-badge>
      </div>
    </h4>
    <div class="card p-3 my-3" v-if="user.id">
      <div class="users-toolbar mb-2">
        <b-dropdown size="sm" variant="link" text="Transfer balanță"  v-if="user.id && user.roles.includes('driver')">
          <b-dropdown-item>Card</b-dropdown-item>
          <b-dropdown-item>Protocol</b-dropdown-item>
          <b-dropdown-item>Discount</b-dropdown-item>
        </b-dropdown>
        <b-button v-b-toggle.driverOrders size="sm" variant="link">Raport comenzi</b-button>
        <b-button size="sm" variant="link">Conversații</b-button>
        <b-button v-if="user.roles.includes('driver')" v-b-toggle.driverLocation :disabled="user.is_online === false" size="sm" variant="link">Localizare</b-button>
      </div>
      <form v-if="user" @submit.prevent="save">
        <b-row>
          <b-col md="4">
            <b-card>
              <b-input-group size="sm" prepend="Nume" class="my-2">
                <input v-model="user.name" type="text" minlength="5" class="form-control" />
              </b-input-group>
              <b-input-group size="sm" prepend="Telefon" class="my-2">
                <input v-model="user.phone" type="text" minlength="5" class="form-control" />
              </b-input-group>
              <b-input-group size="sm" prepend="Email" class="my-2">
                <input v-model="user.email" type="text" minlength="5" class="form-control" />
              </b-input-group>
            </b-card>
          </b-col>
          <b-col  :md="user.roles.includes('driver') ? 6 : 7">
            <b-card>
              <b-input-group size="sm" prepend="Tip cont" class="my-2">
                <Multiselect style="font-size:12px; padding: 0; min-height: 25px; height: 25px; width: calc(100% - 120px)" v-model="user.roles" :multiple="true" :options="['admin', 'dispatcher', 'driver', 'client', 'company']"></Multiselect>
              </b-input-group>
              <b-input-group size="sm" prepend="CUI" class="my-2">
                <b-input v-model="user.cui"></b-input>
              </b-input-group>
              <b-input-group size="sm" prepend="Company Name" class="my-2">
                <b-input v-model="user.company_name"></b-input>
              </b-input-group>
              <b-input-group size="sm" prepend="Cont IBAN/VIVA" class="my-2">
                <b-input-group-prepend>
                  <b-select size="sm" v-model="user.prefered_account" class="rounded-0">
                    <b-select-option value="viva">VIVA</b-select-option>
                    <b-select-option value="bank">IBAN</b-select-option>
                  </b-select>
                </b-input-group-prepend>
                <input v-if="user.prefered_account === 'viva'" :disabled="!user.roles.includes('driver')" v-model="user.viva_account_number" type="text" class="form-control" />
                <input v-if="user.prefered_account === 'bank'" :disabled="!user.roles.includes('driver')" v-model="user.bank_account_number" type="text" class="form-control" />
              </b-input-group>

              <CompanySelect style="float:right; font-size:14px; padding: 0; min-height: 25px; height: 25px; width: calc(100% - 80px)" :multiple="true" v-model="user.companies" :reduce="item => item"></CompanySelect>
              <b-button :disabled="saveInProgress" size="sm" type="submit" class="btn btn-success" style="width: 76px;">
                <template v-if="!saveInProgress">Salvează</template>
                <b-icon v-else icon="three-dots" animation="cylon"></b-icon>
              </b-button>
            </b-card>
          </b-col>
          <b-col v-if="user.id && user.roles.includes('driver')">
            <b-col md="12" class="p-0 ">
              <b-card no-body class="p-1" align="center">
                <div>Balanță:</div>
                <b-badge variant="success">{{ user.balance }} RON</b-badge>
              </b-card>
              <b-card no-body class="p-1 my-2" align="center">
                <div>Discount:</div>
                <b-badge variant="success">{{ user.discount_balance }} RON</b-badge>
              </b-card>
              <b-card no-body class="p-1" align="center">
                <div>IBAN:</div>
                <b-badge v-if="user.viva_account_number" variant="success"> <b-icon scale="1.7" icon="check"></b-icon> </b-badge>
                <b-badge v-else variant="danger"> <b-icon scale="1.7" icon="x"></b-icon> </b-badge>
              </b-card>
            </b-col>
          </b-col>
        </b-row>
      </form>
      <b-card class="my-2"  v-if="user.id && user.roles.includes('driver')">
        <template title>
          <div class="d-flex">
            <div  v-b-toggle.autoData><strong><b-icon icon="caret-down-fill"></b-icon>  Date automobil / Indicativ </strong></div>
            <div class="ml-auto">
            </div>
          </div>

        </template>
        <b-collapse id="autoData" class="mt-2">
        <div v-if="user.id && user.roles.includes('driver')" >
          <UserCars :user-idd="user.id"></UserCars>
        </div>
        </b-collapse>
      </b-card>

      <b-card class="my-2">
        <template title>
          <div class="d-flex">
            <div  v-b-toggle.driverOrders><strong><b-icon icon="caret-down-fill"></b-icon>  Raport comenzi </strong></div>
          </div>
        </template>
        <b-collapse id="driverOrders" class="mt-2">
          <DriverOrders  v-if="user.id && user.roles.includes('driver')"  :user-id="user.id" />
          <UserOrders  v-if="user.id && user.roles.includes('client')"  :user-id="user.id" />
        </b-collapse>
      </b-card>

      <b-card v-if="user.id && user.roles.includes('driver')" class="my-2">
        <template title>
          <div class="d-flex">
            <div v-b-toggle.driverOptions><strong><b-icon icon="caret-down-fill"></b-icon>  Options </strong></div>
          </div>
        </template>
        <b-collapse id="driverOptions" class="mt-2" style="columns: 3;">
          <div v-for="option in $store.state.dispatcherDashboard.orderOptions" :key="'oo-' + option.key" class="mx-2 my-0">
            <input v-model="driverOptions" type="checkbox" :value="option.key" :id="'oo-' + option.key" />
            <label :for="'oo-' + option.key" class="ml-2 position-relative" style="top: -1px;">{{ option.name }}</label>
          </div>
        </b-collapse>
      </b-card>

      <b-card class="my-2" v-if="user.is_online === true && user.default_car">
        <template title>
          <div class="d-flex">
            <div  v-b-toggle.driverLocation><strong><b-icon icon="caret-down-fill"></b-icon>  Localizare șofer </strong></div>
          </div>
        </template>
        <b-collapse id="driverLocation" class="mt-2">
          <GmapMap
            ref="map"
            :center="{lat: user.current_location.coordinates[1], lng: user.current_location.coordinates[0]}"
            :zoom="13"
            style="width: 100%; height: calc(30vh); border-bottom: 1px solid #bdc1c6"
            :key="''"
          >
              <GmapMarker
                :position="{lat: user.current_location.coordinates[1], lng: user.current_location.coordinates[0]}"

                :clickable="true"
                :draggable="false"
                :label="{color:'#fff', fontSize:'9px',fontWeight:'bold', text: user.default_car.indicativ}"
              />
          </GmapMap>
      </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import UserCars from '@/components/users/UserCars'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-best-google-maps'
import DriverOrders from '@/components/orders/GetOrders'
import UserOrders from '@/components/orders/GetOrdersByUser'
// import UserSelect from '@/components/common/UserSelect'
import CompanySelect from '@/components/common/CompanySelect'

Vue.use(VueGoogleMaps, {
  load: {
    key: config.googleMapsApiKey,
    libraries: 'places'
  }
})

export default {
  name: 'UserDetail',
  components: {
    // UserSelect,
    CompanySelect,
    UserCars,
    Multiselect,
    DriverOrders,
    UserOrders
  },
  props: {
    userId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data () {
    return {
      user: {
        id: null,
        name: '',
        email: '',
        phone: '',
        roles: [],
        password: '',
        protocol_parent: null,
        protocol_parent_user: null,
        viva_account_number: null,
        cui: null,
        company_name: null
      },
      rating: null,
      mapCenter: { lat: 46.7735964, lng: 23.6127841 },
      saveInProgress: false
    }
  },
  mounted () {
    if (this.$props.userId !== 0) {
      this.$axios.get(config.baseApiUrl + '/api/users/' + this.$props.userId)
        .then((response) => {
          this.user = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  watch: {
    userId: function () {
      this.$axios.get(config.baseApiUrl + '/api/users/' + this.$props.userId)
        .then((response) => {
          this.user = response.data
        })
        .catch((error) => {
          console.log(error)
        })
      this.$axios.get(config.baseApiUrl + '/api/rating/' + this.$props.userId + '/driver')
        .then((response) => {
          this.rating = response.data.rating
          console.log(this.rating)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  computed: {
    protocolParent: {
      get () {
        return this.user.protocol_parent_user
      },
      set (value) {
        this.user.protocol_parent_user = value
        this.user.protocol_parent = value ? value.id : null
      }
    },
    driverOptions: {
      get () {
        return Object.keys(this.user.driver_options)
      },
      set (value) {
        // eslint-disable-next-line no-return-assign,no-sequences
        this.user.driver_options = value.reduce((acc, curr) => (acc[curr] = true, acc), {})
      }
    }
  },
  methods: {
    save () {
      this.saveInProgress = true
      this.$axios({
        method: this.$props.userId === 0 ? 'post' : 'patch',
        url: config.baseApiUrl + '/api/users' + (this.$props.userId === 0 ? '' : ('/' + this.$props.userId)),
        data: this.user
      })
        .then((response) => {
          this.$toasted.success('Salvat!', {
            duration: 3000
          })
          if (this.$props.userId === 0) {
            this.user = response.data
            this.$props.userId = response.data.id
          }
        })
        .catch((error) => {
          for (const gr in error.response.data.errors) {
            for (const i in error.response.data.errors[gr]) {
              this.$toasted.error(error.response.data.errors[gr][i], {
                duration: 3000
              })
            }
          }
        })
        .finally(() => {
          this.saveInProgress = false
        })
    }
  }
}
</script>

<style scoped>

</style>
