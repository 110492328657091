<template>
  <div>
    <b-button class="mt-3 w-100" size="sm" variant="success" v-b-modal.addCarModal>Adaugă indicativ nou</b-button>
    <b-modal @show="resetModal" hide-footer id="addCarModal" title="Adaugă indicativ nou">
      <form v-if="car" @submit.prevent="save">
        <div class="mb-3">
          <label>Marca / Model</label>
          <input v-model="car.model" type="text" required class="form-control" />
        </div>
        <div class="mb-3">
          <label>Culoare</label>
          <input v-model="car.color" type="text" value="Galben" required class="form-control" />
        </div>
        <div class="mb-3">
          <label>Număr înmatriculare</label>
          <input v-model="car.number" type="text" required class="form-control" />
        </div>
        <div class="mb-3">
          <label>Indicativ</label>
          <input v-model="car.indicativ" type="text" required class="form-control" />
        </div>
        <div class="mb-3">
          <label>Companie</label>
          <CompanySelect v-model="car.companies" :multiple="true" :reduce="item => item"></CompanySelect>
        </div>
        <button type="submit" class="btn btn-success">Adaugă</button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import config from '@/config'
import CompanySelect from '@/components/common/CompanySelect'

export default {
  name: 'AddCar',
  components: {
    CompanySelect
  },
  data () {
    return {
      car: {
        id: null,
        model: '',
        color: '',
        number: '',
        indicativ: '',
        companies: []
      }
    }
  },
  mounted () {
  },
  methods: {
    resetModal () {
      this.car.id = null
      this.car.model = ''
      this.car.color = ''
      this.car.number = ''
      this.car.indicativ = ''
      this.car.companies = []
    },
    save () {
      this.$axios({
        method: 'post',
        url: config.baseApiUrl + '/api/cars',
        data: this.car
      })
        .then((response) => {
          this.$toasted.success('Saved', {
            duration: 3000
          })
          if (this.$route.params.id === '0') {
            this.car = response.data
            this.$router.push({ name: 'CarEdit', params: { id: response.data.id } })
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
