<template>
    <div>
        <b-container fluid>
        <b-row>
            <b-col md="12">
                <UsersList :users-type="$route.params.type"></UsersList>
            </b-col>
        </b-row>
        </b-container>
    </div>
</template>

<script>
import UsersList from '@/components/users/UsersList'
export default {
  name: 'Users',
  components: { UsersList }
}
</script>

<style scoped>

</style>
