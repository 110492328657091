<template>
  <div>
      <form v-if="user" @submit.prevent="save">
        <b-row>
          <b-col md="5">
            <b-card>
              <b-input-group size="sm" prepend="Nume" class="my-2">
                <input v-model="user.name" type="text" minlength="5" class="form-control" />
              </b-input-group>
              <b-input-group size="sm" prepend="Telefon" class="my-2">
                <input v-model="user.phone" type="text" minlength="5" class="form-control" />
              </b-input-group>
              <b-input-group size="sm" prepend="Email" class="my-2">
                <input v-model="user.email" type="text" minlength="5" class="form-control" />
              </b-input-group>
            </b-card>
          </b-col>
          <b-col :md="user.roles.includes('driver') ? 5 : 7">
            <b-card>
              <b-input-group size="sm" prepend="Parola" class="my-2">
                <input v-model="user.password" type="text" minlength="5" class="form-control" />
              </b-input-group>
              <b-input-group size="sm" prepend="Tip cont" class="my-2">
                <Multiselect style="font-size:12px; padding: 0; min-height: 25px; height: 25px; width: calc(50% - 50px)" v-model="user.roles" :multiple="true" :options="['admin', 'dispatcher', 'driver', 'client', 'company']"></Multiselect>

                <CompanySelect style="font-size:14px; padding: 0; min-height: 25px; height: 25px; width: calc(50% - 70px)" :multiple="true" v-model="user.companies" :reduce="item => item"></CompanySelect>
              </b-input-group>
              <b-input-group size="sm" prepend="Cont IBAN/VIVA" class="my-2">
                <b-input-group-prepend>
                  <b-select size="sm" v-model="user.prefered_account" class="rounded-0">
                    <b-select-option value="viva">VIVA</b-select-option>
                    <b-select-option value="bank">IBAN</b-select-option>
                  </b-select>
                </b-input-group-prepend>
                <input v-if="user.prefered_account === 'viva'" :disabled="!user.roles.includes('driver')" v-model="user.viva_account_number" type="text" class="form-control" />
                <input v-if="user.prefered_account === 'bank'" :disabled="!user.roles.includes('driver')" v-model="user.bank_account_number" type="text" class="form-control" />
              </b-input-group>
            </b-card>
          </b-col>
          <b-col v-if="user.id && user.roles.includes('driver')">
            <b-col md="12" class="p-0 ">
              <b-card no-body class="p-1" align="center">
                <div>Balanță:</div>
                <b-badge variant="success">{{ user.balance }} RON</b-badge>
              </b-card>
              <b-card no-body class="p-1 my-2" align="center">
                <div>Discount:</div>
                <b-badge variant="success">{{ user.discount_balance }} RON</b-badge>
              </b-card>
              <b-card no-body class="p-1" align="center">
                <div>IBAN:</div>
                <b-badge v-if="user.viva_account_number" variant="success"> <b-icon scale="1.7" icon="check"></b-icon> </b-badge>
                <b-badge v-else variant="danger"> <b-icon scale="1.7" icon="x"></b-icon> </b-badge>
              </b-card>
            </b-col>
          </b-col>
        </b-row>
        <b-button :disabled="saveInProgress" size="sm" type="submit" class="my-2 w-100 btn btn-success">
          <template v-if="!saveInProgress">Salvează</template>
          <b-icon v-else icon="three-dots" animation="cylon"></b-icon>
        </b-button>
      </form>

      <b-card class="my-2" v-if="user.id && user.roles.includes('driver')">
        <template title>
          <div class="d-flex">
            <div  v-b-toggle.autoData><strong><b-icon icon="caret-down-fill"></b-icon>  Date automobil / Indicativ </strong></div>
            <div class="ml-auto">
              <b-link href="#">Adaugă indicativ nou</b-link>
            </div>
          </div>

        </template>
        <b-collapse id="autoData" class="mt-2">
          <UserCars :user-idd="user.id"></UserCars>
        </b-collapse>
      </b-card>
    </div>
</template>

<script>
import config from '@/config'
import Multiselect from 'vue-multiselect'
import UserCars from '@/components/users/UserCars'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-best-google-maps'
import CompanySelect from '@/components/common/CompanySelect'

Vue.use(VueGoogleMaps, {
  load: {
    key: config.googleMapsApiKey,
    libraries: 'places'
  }
})

export default {
  name: 'AddUser',
  components: {
    // UserSelect,
    Multiselect,
    UserCars,
    CompanySelect
  },
  props: {
    userId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data () {
    return {
      user: {
        id: null,
        name: '',
        email: '',
        phone: '',
        roles: [],
        prefered_account: 'viva',
        bank_account_number: null,
        viva_account_number: null,
        password: '',
        protocol_parent: null,
        protocol_parent_user: null
      },
      mapCenter: { lat: 46.7735964, lng: 23.6127841 },
      saveInProgress: false
    }
  },
  mounted () {
    if (this.$props.userId !== 0) {
      this.$axios.get(config.baseApiUrl + '/api/users/' + this.$props.userId)
        .then((response) => {
          this.user = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  watch: {
    userId: function () {
      this.$axios.get(config.baseApiUrl + '/api/users/' + this.$props.userId)
        .then((response) => {
          this.user = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  computed: {
    protocolParent: {
      get () {
        return this.user.protocol_parent_user
      },
      set (value) {
        this.user.protocol_parent_user = value
        this.user.protocol_parent = value ? value.id : null
      }
    }
  },
  methods: {
    save () {
      this.saveInProgress = true
      this.$axios({
        method: this.$props.userId === 0 ? 'post' : 'patch',
        url: config.baseApiUrl + '/api/users' + (this.$props.userId === 0 ? '' : ('/' + this.$props.userId)),
        data: this.user
      })
        .then((response) => {
          this.$toasted.success('Salvat!', {
            duration: 3000
          })
          if (this.$props.userId === 0) {
            this.user = response.data
            this.$props.userId = response.data.id
          }
        })
        .catch((error) => {
          for (const gr in error.response.data.errors) {
            for (const i in error.response.data.errors[gr]) {
              this.$toasted.error(error.response.data.errors[gr][i], {
                duration: 3000
              })
            }
          }
        })
        .finally(() => {
          this.saveInProgress = false
        })
    }
  }
}
</script>

<style scoped>

</style>
